.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.dateTimePickerSaveScreen::-webkit-calendar-picker-indicator {
  opacity: 1;
  width: 20px;
  height: 20px;
  border-width: thin;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(0%);
  position: absolute;
  left: 0.5rem;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.BodyDiagram {
  flex-direction: row;
}

.BodyDiagram .controls {
  width: 50px;
}

.BodyDiagram svg.zoom {
  transform: scale(2);
}

.a,
.b {
  fill: #677a90;
}
.a {
  clip-rule: evenodd;
}
.c {
  clip-path: url(#a);
}
.d {
  clip-path: url(#b);
}
.e {
  clip-path: url(#c);
}
.f {
  clip-path: url(#e);
}
.g {
  clip-path: url(#g);
}
.h {
  clip-path: url(#i);
}
.i {
  clip-path: url(#k);
}
.j {
  clip-path: url(#m);
}
.k {
  clip-path: url(#o);
}
.l {
  clip-path: url(#q);
}
.m {
  clip-path: url(#s);
}
.n {
  clip-path: url(#u);
}
.o {
  clip-path: url(#w);
}
.p {
  clip-path: url(#y);
}
.q {
  clip-path: url(#aa);
}
.r {
  clip-path: url(#ac);
}
.s {
  clip-path: url(#ae);
}
.t {
  clip-path: url(#ag);
}
.u {
  clip-path: url(#ai);
}
.v {
  clip-path: url(#ak);
}
.w {
  clip-path: url(#am);
}
.x {
  clip-path: url(#ao);
}
.y {
  clip-path: url(#aq);
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

