@tailwind base;
@tailwind components;
@tailwind utilities;

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

select:-webkit-autofill,
select:-webkit-autofill:hover, 
select:-webkit-autofill:focus, 
select:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

body {
  @apply m-0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../src/assets/background.jpg') no-repeat center center fixed; /* Ruta a tu imagen de fondo */
  background-size: cover;
  z-index: -1;
}

body::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(37, 93, 103, 0.7); /* Overlay con color y opacidad */
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  @apply flex justify-center items-center h-screen relative z-10;
}

.box {
  @apply bg-white p-8 rounded-2xl shadow-lg text-center;
}

h2 {
  @apply text-gray-900 mb-4;
}

h3 {
  @apply text-gray-700 mb-4;
}

.card {
  @apply bg-white rounded-2xl shadow-2xl text-center w-64 m-5 flex flex-col justify-between;
}

.card img {
  @apply w-full h-48 object-cover rounded-t-2xl;
}

.card-content {
  @apply p-5;
}

.card h4 {
  @apply text-gray-900 my-4;
}

.card p {
  @apply text-gray-700 mb-5;
}

.button-container {
  @apply flex justify-around;
}

.btn {
  @apply bg-green-700 text-white py-2 px-4 rounded-lg cursor-pointer uppercase text-lg my-3 shadow-md transition-all;
}

.btn:hover {
  @apply bg-green-600;
}

.edit-link {
  @apply text-red-700 font-bold cursor-pointer underline mt-5 inline-block;
}

.text-red-700:hover {
  @apply text-red-600;
}