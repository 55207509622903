.pressure-selector-container {
  width: 100%;
  max-width: 400px;
  margin: auto;
  text-align: center;
  font-family: Arial, sans-serif;
  position: relative;
  padding: 20px;
}

.title {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
  margin-bottom: 20px;
}

.info-icon-container {
  position: absolute;
  top: 0;
  right: 0;
}

.info-icon {
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FBBC05;
  color: white;
}

.labels {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  background: linear-gradient(to right, #d7bbff, #ff9a9e);
  outline: none;
  opacity: 0.9;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4a4a72;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4a4a72;
  cursor: pointer;
}

.values {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 5px;
}

.values span {
  color: #aaa;
}

.values .active {
  color: #4a4a72;
  font-weight: bold;
}
